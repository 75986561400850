@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

#projects {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 5vw 30vw;
    font-family: 'Roboto', sans-serif;
    display: inline-block;
}

#projects h2 {
    font-weight: 500;
    font-size: 1.5rem;
}

#projects h4 {
    font-weight: 600;
    font-size: 1.3rem;
}

.project {
    text-align: left;
    font-weight: 700;
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.project p {
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.5;
}

img {
    max-width: 100%;
}