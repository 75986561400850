@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro&display=swap');

.wrapper {
    font-family: 'Roboto', sans-serif;
}

.landing {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 600px;
    margin: 50px 100px 50px 100px;
    padding: 0;
    display: inline-block;
    text-align: left;
}

@media only screen and (max-width: 1000px) {
    .landing {
        width: auto;
        margin: 5vw 20vw 7.5vw 20vw;
    }
}

.wrapper p, a, text {
    font-family: 'Inter', sans-serif;
    font-size: 1.05rem;
    font-weight: 400;
    line-height: 1.5;
}

.landing h1 {
    /* font-family: 'Zilla Slab', serif; */
    font-family: 'Source Serif Pro', serif;
    font-weight: 700;
    font-size: 2rem;
}

.landing h2 {
    /* font-family: 'Zilla Slab', serif; */
    font-family: 'Source Serif Pro', serif;
    font-weight: 700;
    /* font-size: 2rem; */
}

a {
    /* color: #2c82f1; */
    color: black;
    /* font-weight: 500; */
    text-decoration: underline;
}

#me {
    width: 8rem;
    height: 8rem;
    border: none;
}

.memory {
    margin-bottom: 100px;
}
