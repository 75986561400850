@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

#music {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 5vw 10vw;
    font-family: 'Roboto', sans-serif;
    display: inline-block;
}

li {
    text-align: left;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.2;
}

#music h1 {
    font-family: 'Zilla Slab', serif;
    font-weight: 700;
    font-size: 2rem;
}

#music h4 {
    padding-top: 3rem;
    font-weight: 500;
}

.song {
    font-weight: 700;
    font-size: 1.3rem;
}

.artist {
    font-weight: 400;
    font-size: 1rem;
}

.album {
    font-weight: 500;
    font-size: 0.8rem;
}
