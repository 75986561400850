@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  margin: 0;
  padding: 0;
}

/* html, body {
    max-width: 100%;
    overflow-x: hidden;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.wrapper {
    font-family: 'Roboto', sans-serif;
}

.landing {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 600px;
    margin: 50px 100px 50px 100px;
    padding: 0;
    display: inline-block;
    text-align: left;
}

@media only screen and (max-width: 1000px) {
    .landing {
        width: auto;
        margin: 5vw 20vw 7.5vw 20vw;
    }
}

.wrapper p, a, text {
    font-family: 'Inter', sans-serif;
    font-size: 1.05rem;
    font-weight: 400;
    line-height: 1.5;
}

.landing h1 {
    /* font-family: 'Zilla Slab', serif; */
    font-family: 'Source Serif Pro', serif;
    font-weight: 700;
    font-size: 2rem;
}

a {
    /* color: #2c82f1; */
    color: black;
    /* font-weight: 500; */
    text-decoration: underline;
}

#me {
    width: 8rem;
    height: 8rem;
    border: none;
}
.wrapper {
    font-family: 'Roboto', sans-serif;
}

.landing {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 600px;
    margin: 50px 100px 50px 100px;
    padding: 0;
    display: inline-block;
    text-align: left;
}

@media only screen and (max-width: 1000px) {
    .landing {
        width: auto;
        margin: 5vw 20vw 7.5vw 20vw;
    }
}

.wrapper p, a, text {
    font-family: 'Inter', sans-serif;
    font-size: 1.05rem;
    font-weight: 400;
    line-height: 1.5;
}

.landing h1 {
    /* font-family: 'Zilla Slab', serif; */
    font-family: 'Source Serif Pro', serif;
    font-weight: 700;
    font-size: 2rem;
}

.landing h2 {
    /* font-family: 'Zilla Slab', serif; */
    font-family: 'Source Serif Pro', serif;
    font-weight: 700;
    /* font-size: 2rem; */
}

a {
    /* color: #2c82f1; */
    color: black;
    /* font-weight: 500; */
    text-decoration: underline;
}

#me {
    width: 8rem;
    height: 8rem;
    border: none;
}

.memory {
    margin-bottom: 100px;
}

#music {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 5vw 10vw;
    font-family: 'Roboto', sans-serif;
    display: inline-block;
}

li {
    text-align: left;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.2;
}

#music h1 {
    font-family: 'Zilla Slab', serif;
    font-weight: 700;
    font-size: 2rem;
}

#music h4 {
    padding-top: 3rem;
    font-weight: 500;
}

.song {
    font-weight: 700;
    font-size: 1.3rem;
}

.artist {
    font-weight: 400;
    font-size: 1rem;
}

.album {
    font-weight: 500;
    font-size: 0.8rem;
}

#projects {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 5vw 30vw;
    font-family: 'Roboto', sans-serif;
    display: inline-block;
}

#projects h2 {
    font-weight: 500;
    font-size: 1.5rem;
}

#projects h4 {
    font-weight: 600;
    font-size: 1.3rem;
}

.project {
    text-align: left;
    font-weight: 700;
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.project p {
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.5;
}

img {
    max-width: 100%;
}
#particleCatch {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 5vw 30vw;
    font-family: 'Roboto', sans-serif;
    display: inline-block;
}

.article {
    text-align: left;
    font-weight: 700;
    padding-top: 1rem;
    padding-bottom: 2rem;
    float: left;
}

.article p {
    font-weight: 500;
    font-size: 1.2rem;
}

video, img {
    max-width: 100%;
    /* border: solid 5px black; */
}

